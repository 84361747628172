import React 
// , { useState }
 from "react";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import ContactUs from './Pages/ContactUs';
import JobApplicationForm from './Pages/JobApplicationForm';
import Layout from './Pages/Layout';
import Footer from './components/Footer';
import Header from './components/Header';
import Zalzlah from './components/Zalzlah';
// import Chat from "./components/Chat";
import Video from "./components/Video";



function App() {
  // const [isChatOpen, setIsChatOpen] = useState(false);

  return (
    <>
      <Router>
        <Header />
        {/* <Chat
        onChatToggle={(state) => setIsChatOpen(state)}
          embedUrl="https://a4qzds6s.chat.qbusiness.us-east-1.on.aws/"
        // embedWidth={600}          // Optional
        // embedHeight={550}         // Optional
        // embedOffsetRightPc={5}    // Optional
        // headerText="Chat"         // Optional
        // headerInfo="with us" // Optional
        /> */}


        <Video
          videoUrl="/Assets/Intro/Digital1i-PromotionalVideoForGoogle-Final_.mp4"  
          // isChatOpen={isChatOpen} 
        />

        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/job/:jobId" element={<JobApplicationForm />} />
          <Route path="/zalzalah" element={<Zalzlah />} />
        </Routes>

        <Footer />
      </Router>
    </>
  );
}

export default App;