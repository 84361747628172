import React, { useEffect , useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import GetInTouchForm from './GetInTouchForm';
import './Header.css';


const Header = () => {

  const [searchOpen,
  ] = useState(false);


  const menuItems = [
    { title: 'AI', items: [], id: 'ai' },
    { title: 'Cloud Solutions', items: [], id: 'cloudsolutions' },
    { title: 'Services', items: [], id: 'services' },
    { title: 'Company', items: ['Platforms', 'Industries', 'Technologies'], id: 'company' },
    { title: 'Careers', items: [], id: 'careers' },
  ];

  const [menuOpen, setMenuOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false); // State to handle form visibility
  const [isClosing, setIsClosing] = useState(false);

  const handleLinkClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setMenuOpen(false); // Close menu after clicking a link
  };


  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };


  const handleFormOpen = () => {
    setFormOpen(true);
    document.body.style.overflow = 'hidden'; // Disable scrolling
    document.body.style.width = '100vw'; // Prevent shifting due to scrollbar removal
  };

  const handleFormClose = () => {
    setIsClosing(true); // Start closing animation
    setTimeout(() => {
      setFormOpen(false);
      setIsClosing(false); // Reset closing animation state
      document.body.style.overflow = 'auto'; // Restore scrolling
      document.body.style.width = ''; // Reset width
    }, 800); // Matches closing animation duration (adjust as needed)
  };

  // Ensure scrolling is always restored on unmount or page refresh
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);



  return (
    <>

      <header className={`App-header ${searchOpen ? 'search-open' : ''}`}>
        <nav className={`navbar ${menuOpen ? 'open' : ''}`}>
          <div className="logo-image">
            <a href="/">
              <img
                src="/Assets/Logo/logo.png"
                alt="Digital1i Logo"
                onClick={() => handleLinkClick('home')}
              />
            </a>
          </div>
          <div className="menu-items">
            <ul className="menu">
              {menuItems.map((menuItem, index) => (
                <li className="menu-item" key={index}>
                  <a href={`/#${menuItem.id}`} onClick={() => handleLinkClick(menuItem.id)}>{menuItem.title}</a>
                </li>

              ))}
              <li className="menu-item">
                <a className="menuitemc" href="/ContactUs">ContactUs</a>
              </li>
              <li style={{ cursor: 'text' }} className="menuiteml">
                +1 (872) 314-9331
              </li>
            </ul>


          </div>
          <div className="menu-icon" onClick={handleMenuClick}>
            <i className="fas fa-bars"></i>
          </div>
        </nav>
        <button className="get-in-touch btnTrans" onClick={handleFormOpen}>Get in Touch</button>

      </header>

      <Offcanvas show={formOpen || isClosing} onHide={handleFormClose} placement={'end'}  className={isClosing ? 'closing' : ''} style={{ width: '37rem', backgroundColor: 'rgb(11 12 13)', color: 'white', overflowY: 'auto', overflowX: 'hidden' }}>
        <div className={`getintouch-form ${formOpen ? 'open' : ''}`} id='offCanvasHeading' style={{ textAlign: 'center', backgroundColor: 'rgb(11 12 13)', color: 'white', overflow: 'auto' }}>
          <div className="getintouchclose-form" onClick={handleFormClose}>
            <i className="fas fa-times"></i>
          </div>
          <div className="getintouch-form-content">
            <h2 id='offCanvasHeading' style={{ animation: formOpen ? 'anime 1.0s ease-in-out' : 'none' }}>Got an Idea for Your <span style={{ color: '#00c5d3' }}>Project?</span></h2>
            <p>We’d love to learn more about you and what we can design and build together.</p>
            <GetInTouchForm />
          </div>
        </div>
      </Offcanvas>
    </>


  );
};

export default Header;