import React from "react";
import { FaServer, FaUsers, FaClock, FaRocket } from "react-icons/fa";
import "./AWSMigration.css";

const AWSMigration = () => {
    const stats = [
        {
          icon: <FaServer />,
          percentage: "34%",
          title: "Reduce infrastructure costs by up to 34% with AWS cloud solutions.",
        },
        {
          icon: <FaUsers />,
          percentage: "66%",
          title: "Enhance IT staff productivity by 66% with AWS automation.",
        },
        {
          icon: <FaClock />,
          percentage: "89%",
          title: "Experience a 89% decrease in system downtime post-migration.",
        },
        {
          icon: <FaRocket />,
          percentage: "2.5x",
          title: "Accelerate feature delivery by 2.5x with a cloud-native approach.",
        },
      ];
  return (
    
    <div>

        {/* Hero Section */}
      <div id="hero-section" className="hero-section">
        <h1>AWS Cloud Migration</h1>
        <p>
        Migrate to AWS effortlessly and unlock seamless scalability, security, and performance.
        </p>
      </div>
    <section className="aws-migration">
      

      {/* Benefits Section */}
      <div className="benefits-section">
        
      <h2 className="migration-title">Why Migrate to AWS?</h2>
      <div className="stats-container">
        {stats.map((item, index) => (
          <div key={index} className="stat-box">
            <div className="awsicon">{item.icon}</div>
            <h3 className="percentage">{item.percentage}</h3>
            <p className="stat-title">{item.title}</p>
          </div>
        ))}
      </div>
      

      </div>

      {/* Migration Process Section */}
      <div className="process-section">
        <h2>Our Cloud Migration Process</h2>
        <div className="process-steps">
          <div className="step">
            <h3>1. Discovery</h3>
            <p>We assess your current infrastructure and identify cloud migration requirements.</p>
          </div>
          <div className="step">
            <h3>2. Planning</h3>
            <p>We design a customized migration strategy tailored to your business needs.</p>
          </div>
          <div className="step">
            <h3>3. Execution</h3>
            <p>We seamlessly migrate applications, data, and workloads to AWS.</p>
          </div>
          <div className="step">
            <h3>4. Optimization</h3>
            <p>We fine-tune your cloud environment for maximum performance and cost efficiency.</p>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default AWSMigration;
