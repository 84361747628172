import React, { useState } from 'react';
import './Services.css';


const Services = () => {

    const [showP, setShowP] = useState(true)

    const pDekhao = () => setShowP(false)
    const pMatDekhao = () => setShowP(true)


    return (

        <div id="services" className="services">
            <h1 className="heading">Services</h1>
            <p className="subheading">We provide personalized, end-to-end software services to drive your business success</p>
            <div className="services-grid">


                <div id='uthoKaAbba' onMouseEnter={pDekhao} onMouseLeave={pMatDekhao}>
                    <img src='/Assets/Services/generativeai.jpg' alt='generativeai' style={{ width: '100%', height: '100%' }} />
                    <div id='Utho' style={{ width: '100%', backgroundColor: 'rgba(0,0,0, 0.9)', color: 'white', position: 'absolute', bottom: '0', textAlign: 'center', overflow: 'hidden' }}>
                        <h2 style={{ marginTop: '20px', marginBottom: '20px' }}>Generative AI</h2>
                        <p hidden={showP} style={{ textAlign: 'justify', paddingInline: '30px 30px' }}>We leverage Generative AI to innovate and automate complex processes. Our advanced AI solutions, NLP and deep learning create new possibilities for your business, from content generation to predictive analytics, driving efficiency and enhancing creativity.</p>
                    </div>
                </div>

                <div id='uthoKaAbba' onMouseEnter={pDekhao} onMouseLeave={pMatDekhao}>
                    <img src='/Assets/Services/saas.jpg' alt='generativeai' style={{ width: '100%', height: '100%' }} />
                    <div id='Utho' style={{ width: '100%', backgroundColor: 'rgba(0,0,0, 0.9)', color: 'white', position: 'absolute', bottom: '0', textAlign: 'center', overflow: 'hidden' }}>
                        <h2 style={{ marginTop: '20px', marginBottom: '20px' }}>SAAS Development</h2>
                        <p hidden={showP} style={{ textAlign: 'justify', paddingInline: '30px 30px' }}>We specialize in creating scalable SaaS solutions using cutting-edge technologies to deliver secure, user-friendly, and efficient software that fosters growth and innovation. From concept to deployment and ongoing support, we ensure a seamless development process, enabling software that evolves with your business.</p>
                    </div>
                </div>

                <div id='uthoKaAbba' onMouseEnter={pDekhao} onMouseLeave={pMatDekhao}>
                    <img src='/Assets/Services/bi.jpg' alt='generativeai' style={{ width: '100%', height: '100%' }} />
                    <div id='Utho' style={{ width: '100%', backgroundColor: 'rgba(0,0,0, 0.9)', color: 'white', position: 'absolute', bottom: '0', textAlign: 'center', overflow: 'hidden' }}>
                        <h2 style={{ marginTop: '20px', marginBottom: '20px' }}>Big Data / ETL / BI</h2>
                        <p hidden={showP} style={{ textAlign: 'justify', paddingInline: '30px 30px' }}>We offer comprehensive Big Data, ETL, and Business Intelligence solutions to help you harness the power of your data. Our services enable efficient data extraction, transformation, and loading, coupled with advanced business intelligence tools to drive informed decision-making and strategic growth.</p>
                    </div>
                </div>

                <div id='uthoKaAbba' onMouseEnter={pDekhao} onMouseLeave={pMatDekhao}>
                    <img src='/Assets/Services/devops.jpg' alt='generativeai' style={{ width: '100%', height: '100%' }} />
                    <div id='Utho' style={{ width: '100%', backgroundColor: 'rgba(0,0,0, 0.9)', color: 'white', position: 'absolute', bottom: '0', textAlign: 'center', overflow: 'hidden' }}>
                        <h2 style={{ marginTop: '20px', marginBottom: '20px' }}>Containerization / Kubernetes / DevOps</h2>
                        <p hidden={showP} style={{ textAlign: 'justify', paddingInline: '30px 30px' }}>We provide cutting-edge Containerization and Kubernetes solutions integrated with DevOps practices. We ensure efficient deployment, scalability, and management of your applications, optimizing your development pipeline for faster, more reliable delivery.</p>
                    </div>
                </div>

                <div id='uthoKaAbba' onMouseEnter={pDekhao} onMouseLeave={pMatDekhao}>
                    <img src='/Assets/Services/uiux.jpg' alt='generativeai' style={{ width: '100%', height: '100%' }} />
                    <div id='Utho' style={{ width: '100%', backgroundColor: 'rgba(0,0,0, 0.9)', color: 'white', position: 'absolute', bottom: '0', textAlign: 'center', overflow: 'hidden' }}>
                        <h2 style={{ marginTop: '20px', marginBottom: '20px' }}>User Experience (UI/UX)</h2>
                        <p hidden={showP} style={{ textAlign: 'justify', paddingInline: '30px 30px' }}>We prioritize user-centric design to create intuitive and engaging experiences. Our expert team crafts seamless interfaces that enhance usability and satisfaction, ensuring your software is both functional and delightful to use.</p>
                    </div>
                </div>

                <div id='uthoKaAbba' onMouseEnter={pDekhao} onMouseLeave={pMatDekhao}>
                    <img src='/Assets/Services/wearable.jpg' alt='generativeai' style={{ width: '100%', height: '100%' }} />
                    <div id='Utho' style={{ width: '100%', backgroundColor: 'rgba(0,0,0, 0.9)', color: 'white', position: 'absolute', bottom: '0', textAlign: 'center', overflow: 'hidden' }}>
                        <h2 style={{ marginTop: '20px', marginBottom: '20px' }}>Wearable / Web / Mobile</h2>
                        <p hidden={showP} style={{ textAlign: 'justify', paddingInline: '30px 30px' }}>We excel in creating innovative wearable, web and cross-platform solutions. We ensure your applications provide a seamless experience across various devices, enhancing accessibility and user engagement while leveraging the latest technologies.</p>
                    </div>
                </div>

            </div>
        </div>


    );
};

export default Services;