import React, { useState } from "react";
import './Video.css';
import { PlayCircleIcon } from "@heroicons/react/24/solid";

export default function Video({ videoUrl, isChatOpen }) {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className={`video-container ${isChatOpen ? "hidden" : ""}`}>
            {!isExpanded && (
                <div className="video-button-container">

                    <button className="video-button" onClick={() => setIsExpanded(true)}>
                        <PlayCircleIcon className="video-icon" />
                        <span className="video-tooltip">About Us</span>
                    </button>
                </div>

            )}

            {isExpanded && (
                <div className="video-box">
                    <button className="close-button" onClick={() => setIsExpanded(false)}>X</button>
                    <iframe
                        className="video-iframe"
                        src={videoUrl}
                        title="Intro Video"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
            )}
        </div>
    );
}
